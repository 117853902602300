<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
            <v-layout row wrap justify-end>
              <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.Cash | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.Cash) | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.Techcombank | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.Techcombank) | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!-- <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.Vietinbank | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.Vietinbank) | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex> -->
              <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.Vietcombank | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.Vietcombank) | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!-- <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.MBBank | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.MBBank) | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex> -->
              <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.Yuan | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.Yuan) | currency('¥', 2)}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex class="lg-finance">
                <v-card>
                  <v-app-bar flat dense class="hpo_color">
                    <v-toolbar-title>
                        <h5>{{ recharge_type.COD | display_value(money_sources)}}</h5>
                    </v-toolbar-title>
                  </v-app-bar>
                  <v-card-text>
                      <h4>{{ getSummaryByType(recharge_type.COD) | currency}}</h4>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg12>
          <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
          <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
            <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-end" v-if="item.Source == recharge_type.Yuan || item.Destination == recharge_type.Yuan">{{ item.Amount | currency(item.Type == 0 ? '+' : '-', 2)}}</td>
                <td class="text-end" v-if="item.Source != recharge_type.Yuan && item.Destination != recharge_type.Yuan">{{ item.Amount | currency(item.Type == 0 ? '+' : '-', 0)}}</td>
                <td>{{ item.CreatedBy }}</td>
                <td>{{ item.Source | display_value(moneySources)}}</td>
                <td>{{ item.Destination | display_value(moneySources)}}</td>
                <td>{{ item.Command | display_value(moneyCommands)}}</td>
                <td>{{ item.CreatedDate | display_date}}</td>
                <td class="text-center">
                    <v-icon medium class="mr-2" color="green darken-3" @click="gotoDetail(item.ID)">fa-external-link-alt</v-icon>
                    <v-icon :disabled="!(item.Command <= 90 && adminRole)" medium @click="deleteItem(item.ID)">delete</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogTransfer" persistent max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title>
                <span class="title">Lệnh chuyển tiền</span>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap pa-3>
                    <v-flex lg12 md12 sm12 xs12>
                      <v-select v-model="transferItem.Source" :items="moneySources" item-text="name" item-value="value" label="Chọn nguồn" :rules="requireRules"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <v-select v-model="transferItem.Destination" :items="moneySources" item-text="name" item-value="value" label="Chọn đích" :rules="requireRules"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <h-currency-input v-model="transferItem.Amount" :decimal="0" label="Số tiền cần chuyển" class="input-group--focused" :rules="requireRules"></h-currency-input>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogTransfer = false">Hủy</v-btn>
                <v-btn color="blue darken-1" text @click="onCreateFinance(transferItem)">Đồng ý</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogPay" persistent max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title>
                <span class="title">Lệnh thu/chi</span>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap pa-3>
                    <v-flex lg12 md12 sm12 xs12>
                      <v-select v-model="payItem.Command" :items="moneyPayCommands" item-text="name" item-value="value" label="Phân loại thu/chi" :rules="requireRules" @change="onChangeCommand"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                      <v-textarea label="Nội dung" v-model="payItem.Note" hint="Nhập nội dung"></v-textarea>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12 v-if="payItem.type == 1">
                      <v-select :disabled="payItem.Command==-1" v-model="payItem.Source" :items="moneySources" item-text="name" item-value="value" label="Chọn nguồn" :rules="requireRules"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12 v-if="payItem.type == 0">
                      <v-select v-model="payItem.Destination" :items="moneySources" :disabled="payItem.Command==-1 || payItem.Command==1 || payItem.Command==3" item-text="name" item-value="value" label="Chọn đích" :rules="requireRules"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                        <h-currency-input v-if="payItem.Source == recharge_type.Yuan || payItem.Destination == recharge_type.Yuan" v-model="payItem.Amount" :decimal="2" label="Số tiền" class="input-group--focused" :rules="requireRules"></h-currency-input>
                        <h-currency-input v-if="payItem.Source != recharge_type.Yuan && payItem.Destination != recharge_type.Yuan" v-model="payItem.Amount" :decimal="0" label="Số tiền" class="input-group--focused" :rules="requireRules"></h-currency-input>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogPay = false">Hủy</v-btn>
                <v-btn color="blue darken-1" text @click="onCreateFinance(payItem)">Đồng ý</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
  </v-dialog>
  <v-dialog v-model="dialogDetail" persistent max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title>
                <span class="title">Chi tiết lệnh</span>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap pa-3>
                    <v-flex lg12 md12 sm12 xs12>
                      <v-select disabled v-model="financeReportDetail.Command" :items="moneyPayCommands" item-text="name" item-value="value" label="Lý do"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                      <v-textarea disabled label="Nội dung" v-model="financeReportDetail.Note" hint="Nhập nội dung"></v-textarea>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12 v-if="financeReportDetail.type == -1 || financeReportDetail.type == 0">
                      <v-select disabled v-model="financeReportDetail.Source" :items="moneySources" item-text="name" item-value="value" label="Nguồn"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12 v-if="financeReportDetail.type == -1 || financeReportDetail.type == 1">
                      <v-select disabled v-model="financeReportDetail.Destination" :items="moneySources" item-text="name" item-value="value" label="Đích"></v-select>
                    </v-flex>
                    <v-flex lg12 md12 sm12 xs12>
                      <h-currency-input disabled v-if="financeReportDetail.Source == recharge_type.Yuan || financeReportDetail.Destination == recharge_type.Yuan" v-model="financeReportDetail.Amount" :decimal="2" label="Số tiền" class="input-group--focused"></h-currency-input>
                      <h-currency-input disabled v-if="financeReportDetail.Source != recharge_type.Yuan && financeReportDetail.Destination != recharge_type.Yuan" v-model="financeReportDetail.Amount" :decimal="0" label="Số tiền" class="input-group--focused"></h-currency-input>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDetail = false">Đóng</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
  </v-dialog>
  <v-dialog v-model="dialogLogView" persistent max-width="900px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <v-card-title>
                <span class="title">Nhật ký tài chính</span>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap pa-3>
                  <v-flex lg12>
                    <v-data-table fixed-header height="750px"   :headers="log_headers" :items="log_items" :options.sync="log_pagination" :server-items-length="log_total_rows" :footer-props="items_per_page" :must-sort="true" :loading="log_loading" :mobile-breakpoint="100">
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.DeletedDate | display_date}}</td>
                          <td>{{ item.Username }}</td>
                          <td class="text-end" v-if="item.Source == recharge_type.Yuan || item.Destination == recharge_type.Yuan">{{ item.Amount | currency(item.Type == 0 ? '+' : '-', 2)}}</td>
                          <td class="text-end" v-if="item.Source != recharge_type.Yuan && item.Destination != recharge_type.Yuan">{{ item.Amount | currency(item.Type == 0 ? '+' : '-', 0)}}</td>
                          <td>{{ item.Source | display_value(moneySources)}}</td>
                          <td>{{ item.Destination | display_value(moneySources)}}</td>
                          <td>{{ item.Command | display_value(moneyCommands)}}</td>
                          <td>{{ item.CreatedDate | display_date}}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogLogView = false">Đóng</v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
  </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import { request_list } from "@/commons/hpod";
import Filtering from "@/components/Filtering";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    return {
      valid: true,
      requireRules: [v => parseFloat((v || 0).toString().replace(/[^\d\.\-]/g, "")) > -1 || "Trường dữ liệu không được để trống."],
      requireTextRules: [v => !!v || "Trường dữ liệu không được để trống."],
      headers: [
        {
          text: "Số tiền",
          value: "Amount",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Người tạo",
          value: "CreatedBy",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Nguồn",
          value: "Source",
          filterable: true,
          dataType: configs.DATA_TYPE["Enum"],
          values: configs.MONEY_SOURCES
        },
        {
          text: "Thụ hưởng",
          value: "Destination",
          filterable: true,
          dataType: configs.DATA_TYPE["Enum"],
          values: configs.MONEY_SOURCES
        },
        {
          text: "Lệnh",
          value: "Command",
          filterable: true,
          dataType: configs.DATA_TYPE["Enum"],
          values: configs.MONEY_COMMANDS
        },
        {
          text: "Ngày",
          value: "CreatedDate",
          filterable: true,
          dataType: configs.DATA_TYPE["Date"]
        },
        {
          text: "",
          value: "",
          align: "center",
          sortable: false,
          filterable: false
        }
      ],
      log_headers: [
        {
          text: "Ngày xóa",
          value: "DeletedDate",
          filterable: true,
          dataType: configs.DATA_TYPE["Date"]
        },
        {
          text: "Người xóa",
          value: "Username",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Số tiền",
          value: "Amount",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Nguồn",
          value: "Source",
          filterable: true,
          dataType: configs.DATA_TYPE["Enum"],
          values: configs.MONEY_SOURCES
        },
        {
          text: "Thụ hưởng",
          value: "Destination",
          filterable: true,
          dataType: configs.DATA_TYPE["Enum"],
          values: configs.MONEY_SOURCES
        },
        {
          text: "Lệnh",
          value: "Command",
          filterable: true,
          dataType: configs.DATA_TYPE["Enum"],
          values: configs.MONEY_COMMANDS
        },
        {
          text: "Ngày tạo",
          value: "CreatedDate",
          filterable: true,
          dataType: configs.DATA_TYPE["Date"]
        }
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      log_pagination: {
        ...configs.PAGINATION,
        sortBy: ["CreatedDate"]
      },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      filterConditions: [],
      quickFilterConditions: [],
      viewName: "bao-cao-tai-chinh",
      dateRange: {
        startDate: moment
          .utc(Date.now())
          .startOf("month")
          .toISOString()
          .substr(0, 10),
        endDate: moment
          .utc(Date.now())
          .endOf("month")
          .toISOString()
          .substr(0, 10)
      },
      menuStart: false,
      menuEnd: false,
      recharge_type: configs.RECHARGE_TYPE,
      money_sources: configs.MONEY_SOURCES,
      moneySources: configs.MONEY_SOURCES,
      moneyCommands: configs.MONEY_COMMANDS,
      moneyPayCommands: _.filter(configs.MONEY_COMMANDS, {pay: 1}),
      transferItem: {Command: 1, type: 0},
      payItem: {type: 1, Command: -1},
      dialogTransfer: false,
      dialogPay: false,
      dialogDetail: false,
      dialogLogView: false,
    };
  },
  watch: {
    pagination: {
        handler: function (val, oldVal) {
            let filterConditions = this.$store.state.commons.filterConditions;
            this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
            this.filter_data();
        },
        deep: true
    },
    log_pagination: {
        handler: function (val, oldVal) {
            this.onLogView();
        },
        deep: true
    },
  },
  computed: {
    ...mapState({
      items: state => state.report.financeReport.data,
      total_rows: state => state.report.financeReport.total,
      summary: state => state.report.financeReport.summary,
      financeReportDetail: state => state.report.financeReportDetail,
      loading: state => state.report.loading,
      log_items: state => state.financeLog.all.data,
      log_total_rows: state => state.financeLog.all.total,
      log_loading: state => state.financeLog.loading,
      staffs: state =>
        _.filter(state.commons.all_users, function(o) {
          return ((o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) || ((o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService);
        })
    }),
    computedStartDateFormatted() {
      return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
    },
    computedEndDateFormatted() {
      return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    }
  },
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.$store.dispatch("report/getFinanceReport", param);
    },
    getStaffName(staffId) {
      const staff = _.find(this.staffs, {
        ID: staffId
      });
      return staff ? staff.FullName : "";
    },
    getSummaryByType(type) {
      const item = _.find(this.summary, {'WalletType': type})
      return item ? item.Amount : 0;
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    onTransferMoney() {
      this.transferItem = {ID: 0, Command: 90, type: -1};
      this.dialogTransfer = true;
    },
    onPayMoney() {
      this.payItem = {ID: 0, type: 1, Command: -1};
      this.dialogPay = true;
    },
    gotoDetail(id) {
      this.$store.dispatch("report/getFinanceReportDetail", id);
      setTimeout(() => {this.dialogDetail = true}, 200);
    },
    onChangeCommand() {
      const cmd = _.find(configs.MONEY_COMMANDS, {value: this.payItem.Command});
      if(cmd){
        this.payItem.type = cmd.type;
      }
      if(this.payItem.Command == 1){
        this.payItem.Destination = 92;
      }
    },
    onCreateFinance(newItem) {
      if(this.$refs.form.validate()) {
        if(newItem.type == 0 && !newItem.Destination && newItem.Source){
          newItem.Destination = newItem.Source;
          newItem.Source = null;
        }
        if(newItem.type != -1 && (newItem.Command == 1 || newItem.Command == 3)){
          newItem.Destination = 92;
          newItem.type = 0;
        }
        if(newItem.Destination == undefined){
          newItem.Destination = -1;
        }
        this.$store.dispatch("report/setFinanceReport", {
            data: newItem,
            param: {
              pagination: this.pagination,
              filter: this.filterConditions,
              quickFilter: this.quickFilterConditions
            }
        })
        this.dialogTransfer = false;
        this.dialogPay = false;
      }
    },
    deleteItem: function(id) {
        window.getApp.$emit('REQUEST_DELETE_DATA', id);
    },
    onLogView: function() {
      this.$store.dispatch("financeLog/getList", this.log_pagination);
      setTimeout(() => {this.dialogLogView = true}, 200);
    },
  },
  mounted() {
    this.$store.dispatch("commons/getAllUsers");
    this.filter_data();
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("REQUEST_CREATE_DATA", this.onPayMoney);
    this.$root.$on("REQUEST_SAVE_DATA", this.onTransferMoney);
    this.$root.$on("REQUEST_OTHER_ACTION", this.onLogView);
    this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
        this.$store.dispatch("report/removeFinanceReport", {'id': id, 'param': {
          pagination: this.pagination,
          filter: this.filterConditions,
          quickFilter: this.quickFilterConditions
        }});
        setTimeout(this.filter_data, 200);
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("REQUEST_CREATE_DATA", this.onPayMoney);
    this.$root.$off("REQUEST_SAVE_DATA", this.onTransferMoney);
    this.$root.$off("REQUEST_OTHER_ACTION", this.onLogView);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  }
};
</script>